<template>
   <div
      id="loginWrapper"
      class="ultra-light-bg"
   >
      <Header v-bind:showLogout="showLogout" />
      <form class="grid align-center">
         <div class="col">
            <input
               type="text"
               v-model="username"
               placeholder="Username"
            />
            <br/>
            <input
               type="password"
               v-model="password"
               placeholder="Password"
            />
         </div>
         <div class="col">
            <button
               id="submit"
               type="button"
               class="standard-button"
               :disabled="!loginIsValid"
               v-on:click="onSubmitLogin"
            >
               Login
            </button>
         </div>
      </form>
      <CopyrightFooter/>
   </div> <!-- loginWrapper -->
</template>

<script>
   // Import functions
   import LocalAuth from '@/api/auth.js'
   import * as Utils from '@/api/utils.js'
   import { makeToast } from '@/api/toast.js'
   // Import components
   import Header from '@/components/Header.vue'
   import CopyrightFooter from '@/components/CopyrightFooter.vue'
   // Export this view
   export default {
      name: 'Login',
      props: {
      },
      components: {
         'Header': Header,
         'CopyrightFooter': CopyrightFooter,
      },
      data: () => {
         return {
            username: "",
            password: "",
            showLogout: false
         }
      },
      computed: {
         loginIsValid: function() {
            // Basic validation, check for not null
            return this.username && this.password
         },
      },
      methods: {
         // Handle login form submit
         onSubmitLogin(e) {
            e.preventDefault()
            if(Utils.isDebug()) console.log('onSubmitLogin', e)
            // Package request
            let params = {
               body: {
                  username: this.username,
                  password: this.password
               },
               callback: () => {
                  makeToast('Login success', "success")
                  this.$router.push({ path: '/questions' })
               }
            }
            // Attempt login
            LocalAuth.login(params);
         },
      }
   }
</script>

<style scoped>
   #loginWrapper {
      border-radius: 0.5em;
      max-width: 800px;
      margin: 10em auto 0;
      position: relative;
      text-align: left;
   }
   #submit {
      margin-top: 0;
      margin-right: 2.5em;
   }
   form {
      text-align: center;
      padding: 1em 0 0.5em;
   }
   input {
      font-size: 1.3em;
      padding: 0.5em;
   }
   input:nth-of-type(2) {
      margin-top: 0.3em;
   }
</style>
